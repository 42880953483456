module.exports = {

	formatTimestamp: function(timestamp) {
		if (!timestamp) return '';
		var date = new Date(parseInt(timestamp));
		var timezone = -4;
		var offset_GMT = date.getTimezoneOffset();
		var tempDate = date.getTime();
		var date = new Date(tempDate + offset_GMT * 60 * 1000 + timezone * 60 * 60 * 1000)
		if (!date) return '';
		var YY = date.getFullYear();
		var MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
		var DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
		var hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours());
		var mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes());
		var ss = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
		return YY + '-' + MM + '-' + DD + " " + hh + ':' + mm + ":" + ss;
	}
}