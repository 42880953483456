import Storage from "@/storage/Storage"
import Request from "@/request/Request"
import Service from "@/request/Service"


const CommonMixin = {

	data: function() {
		return {
			// Enumeration,
			Storage,
			Request,
			Service,
		}
	},
	filters: {
		amountFormat: function(value, fixed = 2) {
			if (!value) return '0';
			value = value.toString();
			var radio = Math.pow(10, 2);
			var result = parseFloat(value);
			if (isNaN(result)) {
				return result;
			}
			result = Math.round(value * radio) / radio;
			return result.toFixed(fixed).toLocaleString();
		}
	},
	methods: {

		decimalFixed(num, rate) {
			var radio = Math.pow(10, rate);
			var result = parseFloat(num);
			if (isNaN(result)) {
				return result;
			}
			result = Math.round(num * radio) / radio;
			return result;
		},

	}
}


export default CommonMixin