import {Locale} from '@/i18n/I18n.js'


const I18nMessages =  {
    
}

I18nMessages[Locale.ZH_CN.locale] = require('@/i18n/languages/zh_cn.json')
I18nMessages[Locale.EN.locale] = require('@/i18n/languages/en.json')
I18nMessages[Locale.IND.locale] = require('@/i18n/languages/en.json')
I18nMessages[Locale.ARA.locale] = require('@/i18n/languages/ara.json')


export default I18nMessages;