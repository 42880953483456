<template>
    <div>
        <!-- 标题 -->
        <div class="content-body-header content-body-line">
            <div class="header-content">{{title}}</div>
        </div>
        <el-divider></el-divider>
        <!-- 标题 -->

        <!-- 搜索bar -->
        <el-row v-if="searchBar" :gutter="20">
            <slot name="search-properties"></slot>
            <el-col style="width: 80px;mart"><el-button type="primary" @click="submitSearch()">{{$t('common.search')}}</el-button></el-col>
            <el-col style="width: 80px;"><el-button type="primary" @click="resetSearch()">{{$t('common.reset')}}</el-button></el-col>
        </el-row>   

        <el-row style="margin-top: 50px">
            <slot name="buttons"></slot>
            <el-tooltip
                    :content="$t('common.multi_remove')"
                    v-if="removeButton">
                    <el-button
                        type="danger"
                        size="mini"
                        :disabled="!(selectedItems && selectedItems.length)"
                        @click="multiRemoveClicked" >
                        <i class="fa fa-remove"></i>
                        {{ $t('common.multi_remove')}}
                    </el-button>
                </el-tooltip>
        </el-row>
        
        <el-row style="margin-top: 20px">
            <el-table
                :data="modelList"
                v-loading="tableLoading"
                stripe
                border
                :header-cell-style="{'text-align':'center'}"
                :cell-style="{'text-align':'center'}"
                size="mini"
                style="width: 100%"
                @selection-change="tableSelectionChanged">
                <slot name="table-columns"></slot>
            </el-table>
        </el-row>

        <!-- 分页器 -->
        <div class="content-body-line align-center-horizentol" style="margin-top: 50px;">
            <el-pagination
                background
                layout="total, sizes, prev, pager, next, jumper"
                @size-change="tableSizeChanged"
                @current-change="tablePageChanged"
                :current-page="pagination.page"
                :page-sizes="[20,50,100]"
                :page-size="pagination.size"
                :total="pagination.total"
            ></el-pagination>
        </div>
        <!-- 分页器 -->
    </div>
</template>


<script>
export default {
    name: 'app-pagination',
    data(){
        return {
            tableLoading: false,
            pagination: {
                page: 1,
                
                size: 20,

                total: 0,
            },
            selectedItems: [],
            modelList: [],
        }
    },
    props: {
        value: {
            type: Object,
            default: ()=> {}
        },
        // 页面标题
        title: {
            type: String,
            default: '',
        },
        searchBar: {
            type: Boolean,
            default: false,
        },
        beforePage: {
            type: Function,
            default: function(){}
        },
        pageUrl: {
            type: String,
        },
        pageCountUrl: {
            type: String,
        },
        removeUrl: {
            type: String,
        },
        removeButton: {
            type: Boolean,
            default: true,
        },
        listLoad: {
            type: Function,
            default: ()=>{}
        }
        
    },
    methods: {
        submitSearch(){
            // 搜索以后拿第一页
            this.pagination.page = 1;
            this.requestPageData();
        },
        resetSearch() {
            var obj = {};
            for (var key in this.value) {
                obj[key] = '';
            }
            this.$emit('input',obj);
            this.$nextTick(()=>{
                this.submitSearch();
            })
        },
        requestPageData() {
            this.tableLoading = true;
            var query = {
                page: this.pagination.page,
                size: this.pagination.size
            };
            Object.assign(query, this.value);
            if (this.beforePage && typeof this.beforePage =='function') {
                this.beforePage(query);
            }
            this.Request.post(this.pageUrl, query).then((data)=>{
                this.tableLoading = false;
                if (this.listLoad && typeof this.listLoad == 'function') {
                    this.listLoad(data.body);
                }
                this.modelList = data.body;
            }).catch(()=>{
                this.tableLoading = false;
            })

            this.Request.post(this.pageCountUrl, query).then((data)=>{
                this.pagination.total = data.body;
            }).catch(()=>{})
        },
        tableSelectionChanged(selected) {
            this.selectedItems = selected;
        },
        tablePageChanged(page) {
            this.pagination.page = page;
            this.requestPageData();
        },
        tableSizeChanged(size) {
            this.pagination.size = size;
            this.requestPageData();
        },
        multiRemoveClicked() {
            var ids = [];
            for (var i in this.selectedItems) {
                ids.push(this.selectedItems[i].id);
            }
            this.removeItems(ids);
        },
        /**
         * 
         * 删除数据   
         * @param {*} idList [id,id,id] id列表
         */
        removeItems(idList) {
            this.$confirm(this.$t('common.confirm_remove'), this.$t('common.danger'), {
                type: "error",
            }).then(() => {
                this.Request.post(this.removeUrl,{keys: JSON.stringify(idList)}).then(()=>{
                    this.$notify({
                        message: this.$t('common.remove_success'),
                        title: this.$t('common.success'),
                        type: "success",
                    });
                    this.requestPageData();
                });
            }).catch(() => {});
        },
        removeItem(id) {
            this.removeItems([id]);
        },
        // 获取选中行数据
        getSelected() {
            return this.selectedItems;
        },
        refresh() {
            this.requestPageData();
        }

    },
    mounted: function(){
        this.requestPageData();
    }
}
</script>

<style scoped>

</style>